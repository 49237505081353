<template>
  <div class="tree">
    <ol class="headerTree">
      <li id="rowListHeader"  class="tree-header">
          <div class="tree-column">
            <!-- <a style="visibility: hidden;"><i class="fas fa-chevron-square-down fa-lg pr-2"></i></a> -->
            <div class="company-name-column cursor-pointer" @click="handleSort('company_name')">COMPANY NAME
              <span>
                <i v-if="sortOrder.company_name === 'ascending'" class="el-icon-caret-top"></i>
                <i v-if="sortOrder.company_name === 'descending'" class="el-icon-caret-bottom"></i>
                <i v-if="sortOrder.company_name === ''">
                  <i class="el-icon-caret-top"></i>
                  <i class="el-icon-caret-bottom"></i>
                </i>
              </span>
            </div>
            <div class="tools text-left cursor-pointer" @click="handleSort('full_name')">FULL NAME
              <span>
                <i v-if="sortOrder.full_name === 'ascending'" class="el-icon-caret-top"></i>
                <i v-if="sortOrder.full_name === 'descending'" class="el-icon-caret-bottom"></i>
                <i v-if="sortOrder.full_name === ''">
                  <i class="el-icon-caret-top"></i>
                  <i class="el-icon-caret-bottom"></i>
                </i>
              </span>
            </div>
            <div class="email-column text-left cursor-pointer" @click="handleSort('email')">E-MAIL
              <span>
                <i v-if="sortOrder.email === 'ascending'" class="el-icon-caret-top"></i>
                <i v-if="sortOrder.email === 'descending'" class="el-icon-caret-bottom"></i>
                <i v-if="sortOrder.email === ''">
                  <i class="el-icon-caret-top"></i>
                  <i class="el-icon-caret-bottom"></i>
                </i>
              </span>
            </div>
            <div class="col-action text-left">ACTION</div>
            <div class="sales-column text-left">SALES</div>
            <div class="col-created text-left cursor-pointer" @click="handleSort('created_at')">CREATED
              <span>
                <i v-if="sortOrder.created_at === 'ascending'" class="el-icon-caret-top"></i>
                <i v-if="sortOrder.created_at === 'descending'" class="el-icon-caret-bottom"></i>
                <i v-if="sortOrder.created_at === '' || sortOrder.created_at === null">
                  <i class="el-icon-caret-top"></i>
                  <i class="el-icon-caret-bottom"></i>
                </i>
              </span>
            </div>
          </div>
      </li>
    </ol>
    <div class="el-table__empty-block" style="width: 1203px; height: 100%; overflow: hidden;" v-if="treeData.length == 0">
      <span class="el-table__empty-text mt-4">
        <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
      </span>
    </div>
    <ol class="sortable sitemap-list ui-sortable ">
      <node-tree :rootDomain="rootDomain" :mypackages="mypackages" :node="row" :index="index" v-for="(row,index) in treeData" :key="row.id" :isBestSales="isBestSales(row)" @clickPriceSet="priceSetClick" @clickSalesSet="salesSetClick" :GetDownlineList="GetDownlineList"></node-tree>
    </ol>

    <!-- MODAL SALES PERSON -->
    <modal id="modalSalesSet" :show.sync="modals.salesSetup" headerClasses="justify-content-center" modalContentClasses="customhead">
       <h4 slot="header" class="title title-up">Set Sales Person for : <span style="color:#d42e66">{{AgencyCompanyName}}</span></h4>
       <div style="height:20px">&nbsp;</div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 text-right">
              <i class="fas fa-user pr-2"></i>Sales Representative
              <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Sales Representative"
                  filterable
                  default-first-option
                  v-model="selects.salesRepSelected"
              >
              
                  <el-option
                      v-for="option in selects.salesList"
                      class="select-primary"
                      :value="option.id"
                      :label="option.name"
                      :key="option.id"
                  >
                  </el-option>
              </el-select>
              <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesrep')"><i class="fas fa-times-circle fa-lg"></i></a>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 text-left">
              <i class="fas fa-user-headset pr-2"></i>Account Executive
              <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Account Executive"
                  filterable
                  default-first-option
                  v-model="selects.salesAESelected"
              >
              
                  <el-option
                      v-for="option in selects.salesList"
                      class="select-primary"
                      :value="option.id"
                      :label="option.name"
                      :key="option.id"
                  >
                  </el-option>
              </el-select>
              <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesae')"><i class="fas fa-times-circle fa-lg"></i></a>
            </div>
        </div>
        <div class="row mt-4">&nbsp;</div>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 text-right">
            <i class="fas fa-user-tag pr-2"></i>Referral Account
            <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Referral Account"
                  filterable
                  default-first-option
                  v-model="selects.salesRefSelected"
              >
              
                  <el-option
                      v-for="option in selects.salesList"
                      class="select-primary"
                      :value="option.id"
                      :label="option.name"
                      :key="option.id"
                  >
                  </el-option>
              </el-select>
              <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesref')"><i class="fas fa-times-circle fa-lg"></i></a>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 text-left">
          </div>
        </div>
        <template slot="footer">
          <div class="container text-center pb-4">
            <base-button v-if="this.$global.settingMenuShow_update" style="min-width:120px"  @click.native="saveSalesSet()">Save</base-button>
          </div>
        </template>
    </modal>
    <!-- MODAL SALES PERSON -->
                        <!-- Modal Setting Markup -->
                            <modal id="modalAgencySetPrice" :show.sync="modals.pricesetup" headerClasses="justify-content-center">
                              <h4 slot="header" class="title title-up">Set Cost for Agency: <span style="color:#d42e66">{{AgencyCompanyName}}</span></h4>
                              <!-- <p class="text-center">
                                <a href="#">Click here</a> to watch video if you need more explanation.
                              </p> -->
                              <div style="display:none">
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <div style="height:20px">&nbsp;</div>
                              <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                      <div class="d-inline-block pr-4" v-if="false">
                                            <label>Select Modules:</label>
                                            <el-select
                                                class="select-primary"
                                                size="large"
                                                placeholder="Select Modules"
                                                v-model="selectsAppModule.AppModuleSelect"
                                                style="padding-left:10px"
                                                >
                                                <el-option
                                                    v-for="option in selectsAppModule.AppModule"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.label"
                                                >
                                                </el-option>
                                            </el-select>
                                      </div>
                                      <div class="d-inline-block">
                                            <label>Payment Term:</label>
                                            <el-select
                                                class="select-primary"
                                                size="small"
                                                placeholder="Select Modules"
                                                v-model="selectsPaymentTerm.PaymentTermSelect"
                                                style="padding-left:10px"
                                                @change="paymentTermChange()"
                                                >
                                                <el-option
                                                    v-for="option in selectsPaymentTerm.PaymentTerm"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.label"
                                                >
                                                </el-option>
                                            </el-select>
                                      </div>
                                  </div>
                              </div>
                              <div style="height:20px">&nbsp;</div>
                              <div class="row" style="padding-top: 16px; padding-inline: 16px;">
                                <div class="col-sm-6 menu__prices" style="padding-inline: 16px;" v-if="selectsAppModule.AppModuleSelect == 'LeadsPeek'"  @click="onActiveMenuPrices($global.globalModulNameLink.local.name)" :class="[
                                activeMenuPrices == $global.globalModulNameLink.local.name ? 'active__menu__prices' : '',
                                $global.globalModulNameLink.enhance.name == null && $global.globalModulNameLink.enhance.url == null ? 'col-md-6 col-lg-6' : 'col-md-4 col-lg-4'
                                ]">
                                  <div style="display: flex; justify-content: center;" v-html="$global.globalModulNameLink.local.name"></div>
                                </div>
                                <div class="col-sm-6 menu__prices" @click="onActiveMenuPrices($global.globalModulNameLink.locator.name)" :class="[
                                activeMenuPrices == $global.globalModulNameLink.locator.name ? 'active__menu__prices' : '',
                                $global.globalModulNameLink.enhance.name == null && $global.globalModulNameLink.enhance.url == null ? 'col-md-6 col-lg-6' : 'col-md-4 col-lg-4'
                                ]">

                                  <div style="display: flex; justify-content: center;" v-html="$global.globalModulNameLink.locator.name"></div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4 menu__prices" @click="onActiveMenuPrices($global.globalModulNameLink.enhance.name)" v-if="$global.globalModulNameLink.enhance.name != null && $global.globalModulNameLink.enhance.url != null" :class="activeMenuPrices == $global.globalModulNameLink.enhance.name ? 'active__menu__prices' : ''">
                                  <div style="display: flex; justify-content: center;" v-html="$global.globalModulNameLink.enhance.name"></div>
                                </div>
                                <div style="width: 100%; border: 1px solid gray; height: 1px; margin-top: 16px;"></div>
                               <!-- SITE ID -->
                                <div class="col-12" style="margin-top: 16px; margin-bottom: 16px; padding-left: 0px; padding-right: 0px;" v-if="activeMenuPrices == $global.globalModulNameLink.local.name">
                                  <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                          <div style="line-height:40px">
                                          How much base price for One Time Creative/Set Up Fee? 
                                          </div>
                                          <div>
                                              <base-input
                                                  label=""
                                                  type="text"
                                                  placeholder="0"
                                                  addon-left-icon="fas fa-dollar-sign"
                                                  class="frmSetCost input__setup__prices campaign-cost-input"    
                                                  v-model="LeadspeekPlatformFee"    
                                                  @keyup="set_fee('local','LeadspeekPlatformFee');"
                                                  @blur="handleFormatCurrency('local','LeadspeekPlatformFee')"
                                                  @keydown="restrictInput"  
                                              >
                                              </base-input>
                                          </div>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                            <div style="line-height:40px">
                                            How much base price <span v-html="txtLeadService">per month</span> are you charging your client for Platform Fee? 
                                            </div>
                                            <div>
                                                <base-input
                                                    label=""
                                                    type="text"
                                                    placeholder="0"
                                                    addon-left-icon="fas fa-dollar-sign"
                                                    class="frmSetCost input__setup__prices campaign-cost-input"    
                                                    v-model="LeadspeekMinCostMonth"    
                                                    @keyup="set_fee('local','LeadspeekMinCostMonth');"
                                                    @blur="handleFormatCurrency('local','LeadspeekMinCostMonth')"
                                                    @keydown="restrictInput"      
                                                >
                                                </base-input>
                                            </div>
                                    </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                          <div style="line-height:40px">
                                          How much cost per lead <span v-html="txtLeadOver">from the monthly charge</span>?
                                          </div>
                                          <div>
                                              <base-input
                                                  label=""
                                                  type="text"
                                                  placeholder="0"
                                                  addon-left-icon="fas fa-dollar-sign"
                                                  class="frmSetCost input__setup__prices campaign-cost-input"    
                                                  v-model="LeadspeekCostperlead"    
                                                  @keyup="set_fee('local','LeadspeekCostperlead');"
                                                  @blur="handleFormatCurrency('local','LeadspeekCostperlead')"
                                                  @keydown="restrictInput"   
                                              >
                                              </base-input>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- SITE ID -->

                              <!-- SEARCH ID -->
                               <div class="col-12" style="margin-top: 16px; margin-bottom: 16px; padding-left: 0px; padding-right: 0px;" v-if="activeMenuPrices == $global.globalModulNameLink.locator.name">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div style="line-height:40px">
                                      How much base price for One Time Creative/Set Up Fee? 
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="LocatorPlatformFee"    
                                              @keyup="set_fee('locator','LocatorPlatformFee');"
                                              @blur="handleFormatCurrency('locator','LocatorPlatformFee')"
                                              @keydown="restrictInput"   
                                          >
                                          </base-input>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div style="line-height:40px">
                                      How much base price <span v-html="txtLeadService">per month</span> are you charging your client for Platform Fee? 
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="LocatorMinCostMonth"    
                                              @keyup="set_fee('locator','LocatorMinCostMonth');"
                                              @blur="handleFormatCurrency('locator','LocatorMinCostMonth')"
                                              @keydown="restrictInput"   
                                          >
                                          </base-input>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div class="d-inline pr-3" style="float:left;line-height:40px">
                                      How much cost per lead <span v-html="txtLeadOver">from the monthly charge</span>?
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="LocatorCostperlead"    
                                              @keyup="set_fee('locator','LocatorCostperlead');"
                                              @blur="handleFormatCurrency('locator','LocatorCostperlead')"
                                              @keydown="restrictInput"    
                                          >
                                          </base-input>
                                      </div>
                                  </div>
                                </div>
                               </div>
                              <!-- SEARCH ID -->

                              <!-- ENHANCE ID -->
                               <div class="col-12" style="margin-top: 16px; margin-bottom: 16px; padding-left: 0px; padding-right: 0px;" v-if="activeMenuPrices == $global.globalModulNameLink.enhance.name && $global.globalModulNameLink.enhance.name != null && $global.globalModulNameLink.enhance.url != null">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div style="line-height:40px">
                                      How much base price for One Time Creative/Set Up Fee? 
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="EnhancePlatformFee"    
                                              @keyup="set_fee('enhance','EnhancePlatformFee');"
                                              @blur="handleFormatCurrency('enhance','EnhancePlatformFee')"
                                              @keydown="restrictInput"   
                                          >
                                          </base-input>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div style="line-height:40px">
                                      How much base price <span v-html="txtLeadService">per month</span> are you charging your client for Platform Fee? 
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="EnhanceMinCostMonth"    
                                              @keyup="set_fee('enhance','EnhanceMinCostMonth');"
                                              @blur="handleFormatCurrency('enhance','EnhanceMinCostMonth')"
                                              @keydown="restrictInput"   
                                          >
                                          </base-input>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 container__setup__prices">
                                      <div style="line-height:40px">
                                      How much cost per lead <span v-html="txtLeadOver">from the monthly charge</span>?
                                      </div>
                                      <div>
                                          <base-input
                                              label=""
                                              type="text"
                                              placeholder="0"
                                              addon-left-icon="fas fa-dollar-sign"
                                              class="frmSetCost input__setup__prices campaign-cost-input"    
                                              v-model="EnhanceCostperlead"    
                                              @keyup="set_fee('enhance','EnhanceCostperlead');"
                                              @blur="handleFormatCurrency('enhance','EnhanceCostperlead')"
                                              @keydown="restrictInput"  
                                          >
                                          </base-input>
                                      </div>
                                      <span v-if="errMinCostPerLead" style="color:#942434; font-size:12px;font-weight: 400;line-height: 40px;display: inline;margin-left: .5rem;">*Cost Per Lead Minimum {{ MinCostPerLead }}</span>
                                  </div>
                                </div>
                               </div>
                              <!-- ENHANCE ID -->
                              </div>

                              <template slot="footer">
                                <div class="container text-center pb-4" >
                                  <base-button v-if="this.$global.settingMenuShow_update" style="min-width:120px"  @click.native="saveAgencyCost()">Save</base-button>
                                </div>
                              </template>
                            </modal>
                           <!-- Modal Setting Markup -->

  </div>
</template>

<script>
import NodeTree from "./NodeTree";
import { DatePicker, Select, Option } from 'element-ui';
import { Modal } from 'src/components';
import { formatCurrencyUSD } from '../../util/formatCurrencyUSD'

export default {
  name: 'Tree',
  props: {
    treeData: {},
    rootDomain: '',
    mypackages: {},
    GetDownlineList:{
      type: Function
    },
    sortOrder: {
      company_name: '',
      full_name: '',
      email: '',
      created_at: '',
    },
  },
  components: {
   NodeTree,
   Modal,
   [DatePicker.name]: DatePicker,
   [Option.name]: Option,
   [Select.name]: Select,
  },
  data() {
    return {
      rootcostagency: '',
      MinCostPerLead: '',
      errMinCostPerLead: false,

      modals: {
        pricesetup: false,
        salesSetup: false,
      },

      /** FOR SET PRICE */
      CompanyActiveID: '',
      AgencyCompanyName: '',

      LeadspeekPlatformFee: '0',
      LeadspeekCostperlead: '0',
      LeadspeekMinCostMonth: '0',

      LocatorPlatformFee: '0',
      LocatorCostperlead: '0',
      LocatorMinCostMonth: '0',

      EnhancePlatformFee: '0',
      EnhanceCostperlead: '0',
      EnhanceMinCostMonth: '0',
      
      costagency : {
        local : {
          'Weekly' : {
            LeadspeekPlatformFee: '0',
            LeadspeekCostperlead: '0',
            LeadspeekMinCostMonth: '0',
          },
          'Monthly' : {
            LeadspeekPlatformFee: '0',
            LeadspeekCostperlead: '0',
            LeadspeekMinCostMonth: '0',
          },
          'OneTime' : {
            LeadspeekPlatformFee: '0',
            LeadspeekCostperlead: '0',
            LeadspeekMinCostMonth: '0',
          },
          'Prepaid' : {
            LeadspeekPlatformFee: '0',
            LeadspeekCostperlead: '0',
            LeadspeekMinCostMonth: '0',
          }
        },

        locator : {
          'Weekly' : {
            LocatorPlatformFee: '0',
            LocatorCostperlead: '0',
            LocatorMinCostMonth: '0',
          },
          'Monthly' : {
            LocatorPlatformFee: '0',
            LocatorCostperlead: '0',
            LocatorMinCostMonth: '0',
          },
          'OneTime' : {
            LocatorPlatformFee: '0',
            LocatorCostperlead: '0',
            LocatorMinCostMonth: '0',
          },
          'Prepaid' : {
            LocatorPlatformFee: '0',
            LocatorCostperlead: '0',
            LocatorMinCostMonth: '0',
          }
        },

        enhance : {
          'Weekly' : {
            EnhancePlatformFee: '0',
            EnhanceCostperlead: '0',
            EnhanceMinCostMonth: '0',
          },
          'Monthly' : {
            EnhancePlatformFee: '0',
            EnhanceCostperlead: '0',
            EnhanceMinCostMonth: '0',
          },
          'OneTime' : {
            EnhancePlatformFee: '0',
            EnhanceCostperlead: '0',
            EnhanceMinCostMonth: '0',
          },
          'Prepaid' : {
            EnhancePlatformFee: '0',
            EnhanceCostperlead: '0',
            EnhanceMinCostMonth: '0',
          }
        }
      },

      txtLeadService: 'per week',
      txtLeadIncluded: 'in that weekly charge',
      txtLeadOver: 'from the weekly charge',

      selectsPaymentTerm: {
          PaymentTermSelect: 'Weekly',
          PaymentTerm: [
              // { value: 'One Time', label: 'One Time'},
              // { value: 'Weekly', label: 'Weekly'},
              // { value: 'Monthly', label: 'Monthly'},
          ],
      },
      selectsAppModule: {
            AppModuleSelect: 'LeadsPeek',
            AppModule: [
                { value: 'LeadsPeek', label: 'LeadsPeek' },
            ],
            LeadsLimitSelect: 'Day',
            LeadsLimit: [
                { value: 'Day', label: 'Day'},
            ],
      },
      /** FOR SET PRICE */

      selects: {
        salesList: [],
        salesRepSelected: "",
        salesAESelected: "",
        salesRefSelected: "",
      },

      salesRowUpdate:0,
      activeMenuPrices: this.$global.globalModulNameLink.local.name,
    }
  },
  methods: {
    validateMinCostPerLead(){
      if (this.selectsPaymentTerm.PaymentTermSelect == 'Weekly' && (Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Weekly.EnhanceCostperlead))) {
        this.errMinCostPerLead = false;
        this.EnhanceCostperlead = this.rootcostagency.enhance.Weekly.EnhanceCostperlead;
        this.costagency.enhance.Weekly.EnhanceCostperlead = this.rootcostagency.enhance.Weekly.EnhanceCostperlead;
      } else if (this.selectsPaymentTerm.PaymentTermSelect == 'Monthly' && (Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Monthly.EnhanceCostperlead))) {
        this.errMinCostPerLead = false;
        this.EnhanceCostperlead = this.rootcostagency.enhance.Monthly.EnhanceCostperlead;
        this.costagency.enhance.Monthly.EnhanceCostperlead = this.rootcostagency.enhance.Monthly.EnhanceCostperlead;
      } else if (this.selectsPaymentTerm.PaymentTermSelect == 'One Time' && (Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.OneTime.EnhanceCostperlead))) {
        this.errMinCostPerLead = false;
        this.EnhanceCostperlead = this.rootcostagency.enhance.OneTime.EnhanceCostperlead;
        this.costagency.enhance.OneTime.EnhanceCostperlead = this.rootcostagency.enhance.OneTime.EnhanceCostperlead;
      } else if (this.selectsPaymentTerm.PaymentTermSelect == 'Prepaid' && (Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Prepaid.EnhanceCostperlead))) {
        this.errMinCostPerLead = false;
        this.EnhanceCostperlead = this.rootcostagency.enhance.Prepaid.EnhanceCostperlead;
        this.costagency.enhance.Prepaid.EnhanceCostperlead = this.rootcostagency.enhance.Prepaid.EnhanceCostperlead;
      }
    },
    removeSales(type) {
      if (type == "salesrep") {
        this.selects.salesRepSelected = "";
      }else if (type == "salesae") {
        this.selects.salesAESelected = "";
      }else if (type == "salesref") {
        this.selects.salesRefSelected = "";
      }
    },
    getSalesList(value,index) {
      this.$store.dispatch('getSalesList',{
        companyID: this.$global.idsys,
      }).then(response => {
        if (this.selects.salesList.length == 0) {
          this.selects.salesList = response.params
        }
        this.selects.salesRepSelected = value.salesrepid;
        this.selects.salesAESelected = value.accountexecutiveid;
        this.selects.salesRefSelected = value.accountrefid;
        this.salesRowUpdate = index;
        this.modals.salesSetup = true;
      },error => {
          
      });
    },
    set_fee(type,typevalue) {

        if (type == 'local') {

          if (this.selectsPaymentTerm.PaymentTermSelect == 'Weekly') {
            if (typevalue == 'LeadspeekPlatformFee') {
              this.costagency.local.Weekly.LeadspeekPlatformFee = this.LeadspeekPlatformFee;
            }else if (typevalue == 'LeadspeekCostperlead') {
              this.costagency.local.Weekly.LeadspeekCostperlead = this.LeadspeekCostperlead;
            }else if (typevalue == 'LeadspeekMinCostMonth') {
              this.costagency.local.Weekly.LeadspeekMinCostMonth = this.LeadspeekMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Monthly') {
            if (typevalue == 'LeadspeekPlatformFee') {
              this.costagency.local.Monthly.LeadspeekPlatformFee = this.LeadspeekPlatformFee;
            }else if (typevalue == 'LeadspeekCostperlead') {
              this.costagency.local.Monthly.LeadspeekCostperlead = this.LeadspeekCostperlead;
            }else if (typevalue == 'LeadspeekMinCostMonth') {
              this.costagency.local.Monthly.LeadspeekMinCostMonth = this.LeadspeekMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'One Time') {
            if (typevalue == 'LeadspeekPlatformFee') {
              this.costagency.local.OneTime.LeadspeekPlatformFee = this.LeadspeekPlatformFee;
            }else if (typevalue == 'LeadspeekCostperlead') {
              this.costagency.local.OneTime.LeadspeekCostperlead = this.LeadspeekCostperlead;
            }else if (typevalue == 'LeadspeekMinCostMonth') {
              this.costagency.local.OneTime.LeadspeekMinCostMonth = this.LeadspeekMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Prepaid') {
                if (typevalue == 'LeadspeekPlatformFee') {
                    this.costagency.local.Prepaid.LeadspeekPlatformFee = this.LeadspeekPlatformFee;
                }else if (typevalue == 'LeadspeekCostperlead') {
                    this.costagency.local.Prepaid.LeadspeekCostperlead = this.LeadspeekCostperlead;
                }else if (typevalue == 'LeadspeekMinCostMonth') {
                    this.costagency.local.Prepaid.LeadspeekMinCostMonth = this.LeadspeekMinCostMonth;
                }
            }

        }else if (type == 'locator') {

          if (this.selectsPaymentTerm.PaymentTermSelect == 'Weekly') {
            if (typevalue == 'LocatorPlatformFee') {
              this.costagency.locator.Weekly.LocatorPlatformFee = this.LocatorPlatformFee;
            }else if (typevalue == 'LocatorCostperlead') {
              this.costagency.locator.Weekly.LocatorCostperlead = this.LocatorCostperlead;
            }else if (typevalue == 'LocatorMinCostMonth') {
              this.costagency.locator.Weekly.LocatorMinCostMonth = this.LocatorMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Monthly') {
            if (typevalue == 'LocatorPlatformFee') {
              this.costagency.locator.Monthly.LocatorPlatformFee = this.LocatorPlatformFee;
            }else if (typevalue == 'LocatorCostperlead') {
              this.costagency.locator.Monthly.LocatorCostperlead = this.LocatorCostperlead;
            }else if (typevalue == 'LocatorMinCostMonth') {
              this.costagency.locator.Monthly.LocatorMinCostMonth = this.LocatorMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'One Time') {
            if (typevalue == 'LocatorPlatformFee') {
              this.costagency.locator.OneTime.LocatorPlatformFee = this.LocatorPlatformFee;
            }else if (typevalue == 'LocatorCostperlead') {
              this.costagency.locator.OneTime.LocatorCostperlead = this.LocatorCostperlead;
            }else if (typevalue == 'LocatorMinCostMonth') {
              this.costagency.locator.OneTime.LocatorMinCostMonth = this.LocatorMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Prepaid') {
                if (typevalue == 'LocatorPlatformFee') {
                    this.costagency.locator.Prepaid.LocatorPlatformFee = this.LocatorPlatformFee;
                }else if (typevalue == 'LocatorCostperlead') {
                    this.costagency.locator.Prepaid.LocatorCostperlead = this.LocatorCostperlead;
                }else if (typevalue == 'LocatorMinCostMonth') {
                    this.costagency.locator.Prepaid.LocatorMinCostMonth = this.LocatorMinCostMonth;
                }
            }

        }else if (type == 'enhance') {
          if (this.selectsPaymentTerm.PaymentTermSelect == 'Weekly') {
            if (typevalue == 'EnhancePlatformFee') {
              this.costagency.enhance.Weekly.EnhancePlatformFee = this.EnhancePlatformFee;
            }else if (typevalue == 'EnhanceCostperlead') {
              if(Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Weekly.EnhanceCostperlead)) {
                this.errMinCostPerLead = true;
                this.MinCostPerLead = this.rootcostagency.enhance.Weekly.EnhanceCostperlead
              } else {
                this.errMinCostPerLead = false;
              }
              this.costagency.enhance.Weekly.EnhanceCostperlead = this.EnhanceCostperlead;
            }else if (typevalue == 'EnhanceMinCostMonth') {
              this.costagency.enhance.Weekly.EnhanceMinCostMonth = this.EnhanceMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Monthly') {
            if (typevalue == 'EnhancePlatformFee') {
              this.costagency.enhance.Monthly.EnhancePlatformFee = this.EnhancePlatformFee;
            }else if (typevalue == 'EnhanceCostperlead') {
              if(Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Monthly.EnhanceCostperlead)) {
                this.errMinCostPerLead = true;
                this.MinCostPerLead = this.rootcostagency.enhance.Monthly.EnhanceCostperlead
              } else {
                this.errMinCostPerLead = false;
              }
              this.costagency.enhance.Monthly.EnhanceCostperlead = this.EnhanceCostperlead;
            }else if (typevalue == 'EnhanceMinCostMonth') {
              this.costagency.enhance.Monthly.EnhanceMinCostMonth = this.EnhanceMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'One Time') {
            if (typevalue == 'EnhancePlatformFee') {
              this.costagency.enhance.OneTime.EnhancePlatformFee = this.EnhancePlatformFee;
            }else if (typevalue == 'EnhanceCostperlead') {
              if(Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.OneTime.EnhanceCostperlead)) {
                this.errMinCostPerLead = true;
                this.MinCostPerLead = this.rootcostagency.enhance.OneTime.EnhanceCostperlead
              } else {
                this.errMinCostPerLead = false;
              }
              this.costagency.enhance.OneTime.EnhanceCostperlead = this.EnhanceCostperlead;
            }else if (typevalue == 'EnhanceMinCostMonth') {
              this.costagency.enhance.OneTime.EnhanceMinCostMonth = this.EnhanceMinCostMonth;
            }
          }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Prepaid') {
                if (typevalue == 'EnhancePlatformFee') {
                    this.costagency.enhance.Prepaid.EnhancePlatformFee = this.EnhancePlatformFee;
                }else if (typevalue == 'EnhanceCostperlead') {
                   if(Number(this.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Prepaid.EnhanceCostperlead)) {
                      this.errMinCostPerLead = true;
                      this.MinCostPerLead = this.rootcostagency.enhance.Prepaid.EnhanceCostperlead
                    } else {
                      this.errMinCostPerLead = false;
                    }
                    this.costagency.enhance.Prepaid.EnhanceCostperlead = this.EnhanceCostperlead;
                }else if (typevalue == 'EnhanceMinCostMonth') {
                    this.costagency.enhance.Prepaid.EnhanceMinCostMonth = this.EnhanceMinCostMonth;
                }
            }

          }
       
    },
    resetAgencyCost() {

      this.LeadspeekPlatformFee = '0';
      this.LeadspeekCostperlead = '0';
      this.LeadspeekMinCostMonth = '0';
      this.LocatorPlatformFee = '0';
      this.LocatorCostperlead = '0';
      this.LocatorMinCostMonth = '0';

      this.costagency.local.Weekly.LeadspeekPlatformFee = '0';
      this.costagency.local.Weekly.LeadspeekCostperlead = '0';
      this.costagency.local.Weekly.LeadspeekMinCostMonth = '0';

      this.costagency.local.Monthly.LeadspeekPlatformFee = '0';
      this.costagency.local.Monthly.LeadspeekCostperlead = '0';
      this.costagency.local.Monthly.LeadspeekMinCostMonth = '0';

      this.costagency.local.OneTime.LeadspeekPlatformFee = '0';
      this.costagency.local.OneTime.LeadspeekCostperlead = '0';
      this.costagency.local.OneTime.LeadspeekMinCostMonth = '0';

      if (typeof(this.costagency.local.Prepaid) !== 'undefined') {
        this.costagency.local.Prepaid.LeadspeekPlatformFee = '0';
        this.costagency.local.Prepaid.LeadspeekCostperlead = '0';
        this.costagency.local.Prepaid.LeadspeekMinCostMonth = '0';
      }

      this.costagency.locator.Weekly.LocatorPlatformFee = '0';
      this.costagency.locator.Weekly.LocatorCostperlead = '0';
      this.costagency.locator.Weekly.LocatorMinCostMonth = '0';

      this.costagency.locator.Monthly.LocatorPlatformFee = '0';
      this.costagency.locator.Monthly.LocatorCostperlead = '0';
      this.costagency.locator.Monthly.LocatorMinCostMonth = '0';

      this.costagency.locator.OneTime.LocatorPlatformFee = '0';
      this.costagency.locator.OneTime.LocatorCostperlead = '0';
      this.costagency.locator.OneTime.LocatorMinCostMonth = '0';

      if (typeof(this.costagency.locator.Prepaid) !== 'undefined') {
        this.costagency.locator.Prepaid.LocatorPlatformFee = '0';
        this.costagency.locator.Prepaid.LocatorCostperlead = '0';
        this.costagency.locator.Prepaid.LocatorMinCostMonth = '0';
      }

      this.costagency.enhance.Weekly.EnhancePlatformFee = '0';
      this.costagency.enhance.Weekly.EnhanceCostperlead = '0';
      this.costagency.enhance.Weekly.EnhanceMinCostMonth = '0';

      this.costagency.enhance.Monthly.EnhancePlatformFee = '0';
      this.costagency.enhance.Monthly.EnhanceCostperlead = '0';
      this.costagency.enhance.Monthly.EnhanceMinCostMonth = '0';

      this.costagency.enhance.OneTime.EnhancePlatformFee = '0';
      this.costagency.enhance.OneTime.EnhanceCostperlead = '0';
      this.costagency.enhance.OneTime.EnhanceMinCostMonth = '0';

      if (typeof(this.costagency.enhance.Prepaid) !== 'undefined') {
        this.costagency.enhance.Prepaid.EnhancePlatformFee = '0';
        this.costagency.enhance.Prepaid.EnhanceCostperlead = '0';
        this.costagency.enhance.Prepaid.EnhanceMinCostMonth = '0';
      }
    },
    saveSalesSet() {
        this.$store.dispatch('setSalesPerson', {
            companyID: this.CompanyActiveID,
            salesRep: this.selects.salesRepSelected,
            salesAE: this.selects.salesAESelected,
            salesRef: this.selects.salesRefSelected,
        }).then(response => {
            if (response.result == "success") {
                this.$notify({
                    type: 'success',
                    message: 'Setting has been saved.',
                    icon: 'tim-icons icon-bell-55'
                });  

                var datasales = this.selects.salesList;
                var rowIndex = this.salesRowUpdate;

                let arr = [];
                var index = 0;
                var indexSR = 0;
                var indexAE = 0;
                var indexRF = 0;

                if (this.selects.salesRepSelected != '') {
                  arr.push(this.selects.salesRepSelected );
                  indexSR = index;
                  index++;
                }
                if (this.selects.salesAESelected != '') {
                  arr.push(this.selects.salesAESelected);
                  indexAE = index;
                  index++;
                }
                 if (this.selects.salesRefSelected != '') {
                  arr.push(this.selects.salesRefSelected);
                  indexRF = index;
                  index++;
                }
                let res = arr.map((id) => (datasales.find(x => x.id == id).name));
                
                var salesreps = res[indexSR];
                var accountexecutive = res[indexAE];
                var accountref = res[indexRF];

                var finalsales = "";
                if (this.selects.salesRepSelected != '') {
                  finalsales = finalsales + '<i class="fas fa-user pr-2"></i>' + salesreps + '<br/>';
                  this.treeData[rowIndex].salesrep = salesreps;
                  this.treeData[rowIndex].salesrepid = this.selects.salesRepSelected;
                }else{
                  this.treeData[rowIndex].salesrep = "";
                  this.treeData[rowIndex].salesrepid = "";
                }

                if (this.selects.salesAESelected != '') {
                  finalsales = finalsales + '<i class="fas fa-user-headset pr-2"></i>' +accountexecutive + '<br/>';
                  this.treeData[rowIndex].accountexecutive = accountexecutive;
                  this.treeData[rowIndex].accountexecutiveid = this.selects.salesAESelected;
                }else{
                  this.treeData[rowIndex].accountexecutive = "";
                  this.treeData[rowIndex].accountexecutiveid = "";
                }

                if (this.selects.salesRefSelected != '') {
                  finalsales = finalsales + '<i class="fas fa-user-tag pr-2"></i>' + accountref + '<br/>';
                  this.treeData[rowIndex].accountref = accountref;
                  this.treeData[rowIndex].accountrefid = this.selects.salesRefSelected;
                }else{
                  this.treeData[rowIndex].accountref = "";
                  this.treeData[rowIndex].accountrefid = "";
                }

                if (finalsales != "") {
                  finalsales = '<div style="line-height:30px;padding-top:10px;padding-bottom:10px">' + finalsales + '</div>';
                }
                $('#salesperson_' + this.CompanyActiveID).html(finalsales);

                if (this.selects.salesRepSelected == "" && this.selects.salesAESelected == ""  && this.selects.salesRefSelected == "") {
                  $('#iconsalesperson_' + this.CompanyActiveID).css('color',"gray");
                }else{
                  $('#iconsalesperson_' + this.CompanyActiveID).css('color',"orange");
                }

                this.GetDownlineList('created_at', 'descending')
                
                this.modals.salesSetup = false;
            }
        },error => {
                    
        });
      //}else{
      //  this.modals.salesSetup = false;
      //}
    },
    saveAgencyCost() {
      this.$store.dispatch('updateGeneralSetting', {
          companyID: this.CompanyActiveID,
          actionType: 'customsmtpmodule',
          comsetname: 'costagency',
          comsetval: this.costagency,
      }).then(response => {
          if (response.result == "success") {
              this.$notify({
                  type: 'success',
                  message: 'Setting has been saved.',
                  icon: 'tim-icons icon-bell-55'
              });  

              this.modals.pricesetup = false;
          }
      },error => {
                  
      });
    },
    salesSetClick(value,index) {
      //console.log(value);
      this.AgencyCompanyName = value.company_name;
      this.CompanyActiveID = value.company_id;
      this.getSalesList(value,index);
    },
    priceSetClick(value) {
      this.AgencyCompanyName = value.company_name;
      this.resetAgencyCost();

      this.CompanyActiveID = value.company_id;
      this.$store.dispatch('getGeneralSetting', {
          companyID: value.company_id,
          settingname: 'costagency',
          idSys: this.$global.idsys
      }).then(response => {
          //console.log(response.data);
          if (response.data != '') {
             this.costagency = response.data;
             this.rootcostagency = response.rootcostagency;
             this.selectsPaymentTerm.PaymentTermSelect = response.dpay ? response.dpay : 'Weekly';

             if(Number(this.costagency.enhance.Weekly.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Weekly.EnhanceCostperlead)) {
              this.costagency.enhance.Weekly.EnhanceCostperlead = this.rootcostagency.enhance.Weekly.EnhanceCostperlead;
             }
             if(Number(this.costagency.enhance.Monthly.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Monthly.EnhanceCostperlead)) {
              this.costagency.enhance.Monthly.EnhanceCostperlead = this.rootcostagency.enhance.Monthly.EnhanceCostperlead;
             }
             if(Number(this.costagency.enhance.OneTime.EnhanceCostperlead) < Number(this.rootcostagency.enhance.OneTime.EnhanceCostperlead)) {
              this.costagency.enhance.OneTime.EnhanceCostperlead = this.rootcostagency.enhance.OneTime.EnhanceCostperlead;
             }
             if(Number(this.costagency.enhance.Prepaid.EnhanceCostperlead) < Number(this.rootcostagency.enhance.Prepaid.EnhanceCostperlead)) {
              this.costagency.enhance.Prepaid.EnhanceCostperlead = this.rootcostagency.enhance.Prepaid.EnhanceCostperlead;
             }
             
             if (response.dpay == 'Weekly') {
              this.txtLeadService = 'per week';
              this.txtLeadIncluded = 'in that weekly charge';
              this.txtLeadOver ='from the weekly charge';
              this.LeadspeekPlatformFee = this.costagency.local.Weekly.LeadspeekPlatformFee;
              this.LeadspeekCostperlead = this.costagency.local.Weekly.LeadspeekCostperlead;
              this.LeadspeekMinCostMonth = this.costagency.local.Weekly.LeadspeekMinCostMonth;

              this.LocatorPlatformFee  = this.costagency.locator.Weekly.LocatorPlatformFee;
              this.LocatorCostperlead = this.costagency.locator.Weekly.LocatorCostperlead;
              this.LocatorMinCostMonth = this.costagency.locator.Weekly.LocatorMinCostMonth

              this.EnhancePlatformFee  = this.costagency.enhance.Weekly.EnhancePlatformFee;
              this.EnhanceCostperlead = this.costagency.enhance.Weekly.EnhanceCostperlead;
              this.EnhanceMinCostMonth = this.costagency.enhance.Weekly.EnhanceMinCostMonth
             }else if (response.dpay == 'Monthly') {
              this.txtLeadService = 'per month';
              this.txtLeadIncluded = 'in that monthly charge';
              this.txtLeadOver ='from the monthly charge';
              this.LeadspeekPlatformFee = this.costagency.local.Monthly.LeadspeekPlatformFee;
              this.LeadspeekCostperlead = this.costagency.local.Monthly.LeadspeekCostperlead;
              this.LeadspeekMinCostMonth = this.costagency.local.Monthly.LeadspeekMinCostMonth;

              this.LocatorPlatformFee  = this.costagency.locator.Monthly.LocatorPlatformFee;
              this.LocatorCostperlead = this.costagency.locator.Monthly.LocatorCostperlead;
              this.LocatorMinCostMonth = this.costagency.locator.Monthly.LocatorMinCostMonth

              this.EnhancePlatformFee  = this.costagency.enhance.Monthly.EnhancePlatformFee;
              this.EnhanceCostperlead = this.costagency.enhance.Monthly.EnhanceCostperlead;
              this.EnhanceMinCostMonth = this.costagency.enhance.Monthly.EnhanceMinCostMonth
             }

             if (typeof(this.costagency.local.Prepaid) == 'undefined') {
                this.$set(this.costagency.local,'Prepaid',{
                  LeadspeekPlatformFee: '0',
                  LeadspeekCostperlead: '0',
                  LeadspeekMinCostMonth: '0',
                });
             }

             if (typeof(this.costagency.locator.Prepaid) == 'undefined') {
                this.$set(this.costagency.locator,'Prepaid',{
                  LocatorPlatformFee: '0',
                  LocatorCostperlead: '0',
                  LocatorMinCostMonth: '0',
                });
             }

             if (typeof(this.costagency.enhance.Prepaid) == 'undefined') {
                this.$set(this.costagency.enhance,'Prepaid',{
                  EnhancePlatformFee: '0',
                  EnhanceCostperlead: '0',
                  EnhanceMinCostMonth: '0',
                });
             }

             if (response.dpay == 'Prepaid') {
              this.txtLeadService = 'per month';
              this.txtLeadIncluded = 'in that monthly charge';
              this.txtLeadOver ='from the monthly charge';
              this.LeadspeekPlatformFee = this.costagency.local.Prepaid.LeadspeekPlatformFee;
              this.LeadspeekCostperlead = this.costagency.local.Prepaid.LeadspeekCostperlead;
              this.LeadspeekMinCostMonth = this.costagency.local.Prepaid.LeadspeekMinCostMonth;

              this.LocatorPlatformFee  = this.costagency.locator.Prepaid.LocatorPlatformFee;
              this.LocatorCostperlead = this.costagency.locator.Prepaid.LocatorCostperlead;
              this.LocatorMinCostMonth = this.costagency.locator.Prepaid.LocatorMinCostMonth

              this.EnhancePlatformFee  = this.costagency.enhance.Prepaid.EnhancePlatformFee;
              this.EnhanceCostperlead = this.costagency.enhance.Prepaid.EnhanceCostperlead;
              this.EnhanceMinCostMonth = this.costagency.enhance.Prepaid.EnhanceMinCostMonth
             }

          }
          this.modals.pricesetup = true;
      },error => {
            
      });
      
    },
    paymentTermChange() {
        if (this.selectsPaymentTerm.PaymentTermSelect == 'Weekly') {
            this.txtLeadService = 'per week';
            this.txtLeadIncluded = 'in that weekly charge';
            this.txtLeadOver ='from the weekly charge';

            /** SET VALUE */
             this.LeadspeekPlatformFee = this.costagency.local.Weekly.LeadspeekPlatformFee;
             this.LeadspeekCostperlead = this.costagency.local.Weekly.LeadspeekCostperlead;
             this.LeadspeekMinCostMonth = this.costagency.local.Weekly.LeadspeekMinCostMonth;

             this.LocatorPlatformFee  = this.costagency.locator.Weekly.LocatorPlatformFee;
             this.LocatorCostperlead = this.costagency.locator.Weekly.LocatorCostperlead;
             this.LocatorMinCostMonth = this.costagency.locator.Weekly.LocatorMinCostMonth
             
             this.EnhancePlatformFee  = this.costagency.enhance.Weekly.EnhancePlatformFee;
             this.EnhanceCostperlead = this.costagency.enhance.Weekly.EnhanceCostperlead;
             this.EnhanceMinCostMonth = this.costagency.enhance.Weekly.EnhanceMinCostMonth

            /** SET VALUE */
        }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Monthly') {
            this.txtLeadService = 'per month';
            this.txtLeadIncluded = 'in that monthly charge';
            this.txtLeadOver ='from the monthly charge';

            /** SET VALUE */
             this.LeadspeekPlatformFee = this.costagency.local.Monthly.LeadspeekPlatformFee;
             this.LeadspeekCostperlead = this.costagency.local.Monthly.LeadspeekCostperlead;
             this.LeadspeekMinCostMonth = this.costagency.local.Monthly.LeadspeekMinCostMonth;
             
             this.LocatorPlatformFee  = this.costagency.locator.Monthly.LocatorPlatformFee;
             this.LocatorCostperlead = this.costagency.locator.Monthly.LocatorCostperlead;
             this.LocatorMinCostMonth = this.costagency.locator.Monthly.LocatorMinCostMonth

             this.EnhancePlatformFee  = this.costagency.enhance.Monthly.EnhancePlatformFee;
             this.EnhanceCostperlead = this.costagency.enhance.Monthly.EnhanceCostperlead;
             this.EnhanceMinCostMonth = this.costagency.enhance.Monthly.EnhanceMinCostMonth
            /** SET VALUE */
        }else if (this.selectsPaymentTerm.PaymentTermSelect == 'One Time') {
            this.txtLeadService = '';
            this.txtLeadIncluded = '';
            this.txtLeadOver ='';

            /** SET VALUE */
             this.LeadspeekPlatformFee = this.costagency.local.OneTime.LeadspeekPlatformFee;
             this.LeadspeekCostperlead = this.costagency.local.OneTime.LeadspeekCostperlead;
             this.LeadspeekMinCostMonth = this.costagency.local.OneTime.LeadspeekMinCostMonth;
             
             this.LocatorPlatformFee  = this.costagency.locator.OneTime.LocatorPlatformFee;
             this.LocatorCostperlead = this.costagency.locator.OneTime.LocatorCostperlead;
             this.LocatorMinCostMonth = this.costagency.locator.OneTime.LocatorMinCostMonth

             this.EnhancePlatformFee  = this.costagency.enhance.OneTime.EnhancePlatformFee;
             this.EnhanceCostperlead = this.costagency.enhance.OneTime.EnhanceCostperlead;
             this.EnhanceMinCostMonth = this.costagency.enhance.OneTime.EnhanceMinCostMonth
            /** SET VALUE */

        }else if (this.selectsPaymentTerm.PaymentTermSelect == 'Prepaid') {
            this.txtLeadService = 'per month';
            this.txtLeadIncluded = 'in that monthly charge';
            this.txtLeadOver ='from the monthly charge';

            /** SET VALUE */

            this.LeadspeekPlatformFee = (typeof(this.costagency.local.Prepaid) !== 'undefined')?this.costagency.local.Prepaid.LeadspeekPlatformFee:0;
            this.LeadspeekCostperlead = (typeof(this.costagency.local.Prepaid) !== 'undefined')?this.costagency.local.Prepaid.LeadspeekCostperlead:0;
            this.LeadspeekMinCostMonth = (typeof(this.costagency.local.Prepaid) !== 'undefined')?this.costagency.local.Prepaid.LeadspeekMinCostMonth:0;
            
            this.LocatorPlatformFee  = (typeof(this.costagency.locator.Prepaid) !== 'undefined')?this.costagency.locator.Prepaid.LocatorPlatformFee:0;
            this.LocatorCostperlead = (typeof(this.costagency.locator.Prepaid) !== 'undefined')?this.costagency.locator.Prepaid.LocatorCostperlead:0;
            this.LocatorMinCostMonth = (typeof(this.costagency.locator.Prepaid) !== 'undefined')?this.costagency.locator.Prepaid.LocatorMinCostMonth:0;
            
            this.EnhancePlatformFee  = (typeof(this.costagency.enhance.Prepaid) !== 'undefined')?this.costagency.enhance.Prepaid.EnhancePlatformFee:0;
            this.EnhanceCostperlead = (typeof(this.costagency.enhance.Prepaid) !== 'undefined')?this.costagency.enhance.Prepaid.EnhanceCostperlead:0;
            this.EnhanceMinCostMonth = (typeof(this.costagency.enhance.Prepaid) !== 'undefined')?this.costagency.enhance.Prepaid.EnhanceMinCostMonth:0;
            
            /** SET VALUE */

        }
    },
    handleSort(column) {
      // Reset other columns' sortOrder to ''
      for (let key in this.sortOrder) {
        if (key !== column) {
          this.sortOrder[key] = '';
        }
      }
      // Toggle sort order for the clicked column
      if (this.sortOrder[column] === '' || this.sortOrder[column] === null) {
        this.sortOrder[column] = 'ascending';
      } else if (this.sortOrder[column] === 'ascending') {
        this.sortOrder[column] = 'descending';
      } else {
        this.sortOrder[column] = '';
      }

      if(column == 'created_at' && this.sortOrder[column] == ''){
        this.sortOrder[column] = null
      }
      
      this.$emit('update-order-by', this.sortOrder[column])
      this.GetDownlineList(column, this.sortOrder[column])
    },
    isBestSales(row) {
      return !!(row.salesrepid || row.accountexecutiveid || row.accountrefid);
    },
    onActiveMenuPrices(value){
      this.activeMenuPrices = value
    },
    handleFormatCurrency(type, field){
      const validInput = /^[0-9]*(\.[0-9]*)?$/;

      if(!validInput.test(this[field])){
        this[field] = 0
      }

      if(field == 'EnhanceCostperlead'){
        this.validateMinCostPerLead()
      }

      const formatNumber = formatCurrencyUSD(this[field])
      this[field] = formatNumber
      this.set_fee(type, field)
    },
    restrictInput(event) {
      const char = event.key;

      if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(char)) {
          return; 
      }

      if (!char.match(/[0-9]/) && char !== '.') {
          event.preventDefault();
      }
    },
  },
  mounted() {
    this.selectsPaymentTerm.PaymentTerm = this.$global.rootpaymentterm;
  },
};
</script>

<style>
#modalAgencySetPrice .select-primary.el-select .el-input input, h3 {
  color: #525f7f;
}

#modalSalesSet .select-primary.el-select .el-input input, h3 {
  color: #525f7f;
}

#modalAgencySetPrice input:read-only {
    background-color: white;
}

#modalAgencySetPrice .el-input__prefix, #modalSetPrice .el-input__suffix {
    color: #525f7f;
}

#modalAgencySetPrice .leadlimitdate {
    width: auto !important;
}

#modalAgencySetPrice .el-input__inner {
    background-color: transparent;
    border-width: 1px;
    border-color: #2b3553;
    color: #942434;
}

.input__setup__prices .input-group .input-group-prepend .input-group-text i {
    color: #525f7f;
}

.input__setup__prices .input-group input[type=text],.input__setup__prices .input-group .input-group-prepend .input-group-text {
    color: #cad1d7;
    border-color: #cad1d7;
    border: 1px solid #cad1d7;
    padding: 10px;
}

.headerTree {
  width: 100%;
  margin:0;
  padding:0;
}

ol.sortable {
  margin:0;
  padding:0;
}

.list-drag {
  padding-right: 10px;
}

.treecolumn {
  min-width: 320px;
  max-width: 320px;
  text-align: left;
}
.email-column {
  min-width: 350px;
  max-width: 350px;
  text-align: left;
}
.sales-column {
  min-width: 180px;
  max-width: 180px;
  text-align: left;
}
.tree{
  overflow-x: auto;
}
.col-created {
  min-width: 120px;
  text-align: center;
}

.col-action {
  min-width: 220px;
  text-align: center;
  margin-left: 25px;
}


.tools {
  /* margin-left: auto; */
  justify-content: center;
  min-width: 250px;
  text-align: left;
}

.col-action .action {
  padding-right:10px;
}

.tree-header .tree-column div {
   color: var(--text-primary-color);
   font-size: 12px;  
   text-transform: uppercase;
   font-weight: 700;
}

ol {
  width: 100%;
  margin: 0;
}


ol li {
  display: flex;
  flex-wrap: wrap;
  line-height: 50px;
}

ol li > ol {
  /*background-color:green;*/
  margin:0;
}

/*ol li > ol li .tree-column div:first-child{
  padding-left: 20px;
}*/


.tree-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
   border-bottom: 1px solid grey;
   color: var(--text-primary-color) !important;
  font-size: 14px;
}

.placeholder {
	 outline: 1px dashed #4183C4;
}

.node-tree .row {
  width: 100%;
}
.node-tree{
  /* width: max-content; */
}
/*.tree-list ul {
  padding-left: 16px;
  margin: 6px 0;
}*/

/*.tree-list .node-tree {
    display: table-row;
    color: rgba(255, 255, 255, 0.7);

}

.tree-list .tree-header{
    display: table-row;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;  
    text-transform: uppercase;
    font-weight: 700;
}

.tree-list .tree-header span {
    border:solid;
    text-align: left;
    display: table-cell;
    padding: 6px;
    vertical-align: middle;
    
}

.tree-list .node-tree span {
    border:solid;
    text-align: left;
    display: table-cell;
    padding: 6px;
    vertical-align: middle;
    
}
*/
.company-name-column{
  min-width: 220px;
  max-width: 220px;
}

.card_continer_setup_price {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.menu__prices {
  padding: 8px 16px;
  border-radius: 4px;
  color: gray;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid transparent;
  transition: border 300ms ease;
}

.active__menu__prices {
  color: black;
  border: 1px solid #222a42;
}

.container__setup__prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input__setup__prices {
  width: 120px;
}

@media screen and (max-width: 767px) {
  .container__setup__prices {
    display: block;
  }
  .input__setup__prices {
  width: 100%;
}
}
</style>